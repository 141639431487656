import { Transition } from "@components/motion/Transition"
import { ContentBlock, ContentBlocks } from "eddev/blocks"

type Props = {
  blocks?: ContentBlock[] | null
}

export function CenteredContentBlocks(props: Props) {
  return (
    <ContentBlocks
      blocks={props.blocks!}
      wrapBlock={(child, block) => {
        const { selfAnimates, fullWidth } = block.current.flags ?? {}
        if (!fullWidth) {
          child = (
            <div className="grid-auto">
              <div className="col-span-12 md:col-start-3 md:col-span-8 lg:col-start-4 lg:col-span-6">{child}</div>
            </div>
          )
        }
        if (!selfAnimates && block.current.slug !== ("core/rich-text" as any)) {
          child = <div data-transition="fade">{child}</div>
        }
        return child
      }}
      spacer={({ prev, next }) => (next === null && !prev?.flags?.solidBg ? <div className="h-10" /> : null)}
    />
  )
}
